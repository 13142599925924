import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';
import { Theme as BrandTheme } from '@vakantiesnl/types';

import type { LinkProps } from './Link';
import { type NewTheme } from '../../styles/themes/zoover/newTheme';

type LinkVariants = keyof Pick<NewTheme['typography'], 'linkLg' | 'linkMd' | 'linkSm'>;
type LinkSizeProps = Required<Pick<LinkProps, 'size'>>['size'];

const sizeToFontVariantMap: Record<LinkSizeProps, LinkVariants> = {
	small: 'linkSm',
	medium: 'linkMd',
	large: 'linkLg',
};

export const useCustomStyles = makeStyles<Pick<LinkProps, 'size'>, 'icon'>({
	name: 'LinkComponent',
	uniqId: 'link-component',
})((theme, { size = 'small' }, classes) => {
	const fontVariant = sizeToFontVariantMap[size];

	// todo: update theme so the same token can be used here regardless of theme
	const defaultColor =
		theme.themeName === BrandTheme.zvrnl ? theme.link.color.light.default : theme.palette.neutral['60'];
	const hoverColor = theme.themeName === BrandTheme.zvrnl ? theme.link.color.light.hover : theme.primary.color.hover;
	const focusColor = theme.themeName === BrandTheme.zvrnl ? theme.link.color.light.focus : theme.primary.color.focus;
	const pressedColor =
		theme.themeName === BrandTheme.zvrnl ? theme.link.color.light.pressed : theme.primary.color.pressed;

	return {
		buttonLink: {
			border: 'none',
			padding: 0,
			background: 'none',
		},
		buttonWithIcon: {
			display: 'flex',
			alignItems: 'center',
		},
		defaultLink: {
			font: theme.typography[fontVariant],
			textDecoration: theme.decoration.link,
			color: defaultColor,
			cursor: 'pointer',
			'&:hover': {
				color: hoverColor,
				[`& .${classes.icon}`]: {
					color: hoverColor,
				},
			},
			'&:focus': {
				color: focusColor,
				[`& .${classes.icon}`]: {
					color: focusColor,
				},
			},
			'&:active': {
				color: pressedColor,
				[`& .${classes.icon}`]: {
					color: pressedColor,
				},
			},
		},
		icon: {
			color: defaultColor,
			marginRight: theme.spacing.xxsmall,
		},
	};
});
