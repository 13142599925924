import { type FC, type ReactNode } from 'react';

import { Icon, type IconTypes } from '@vakantiesnl/components/src/atoms/Icon';
import { default as NextLink, type LinkProps as NextLinkProps } from 'next/link';

import { useCustomStyles } from './Link.style';

export type LinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps> &
	Omit<NextLinkProps, 'href' | 'onClick'> & {
		children: ReactNode;
		icon?: IconTypes;
		size?: 'small' | 'medium' | 'large';
		className?: string;
		'data-cy'?: string;
	} & (
		| {
				onClick: React.MouseEventHandler<HTMLButtonElement>;
				href?: never;
		  }
		| {
				onClick?: NextLinkProps['onClick'];
				href: NextLinkProps['href'];
		  }
	);

const Link: FC<LinkProps> = ({ children, icon, size = 'small', className, onClick, href, ...rest }) => {
	const { classes, cx } = useCustomStyles({ size });

	if (href === undefined) {
		return (
			<button
				data-cy={rest?.['data-cy']}
				className={cx(classes.defaultLink, classes.buttonLink, { [classes.buttonWithIcon]: !!icon }, className)}
				onClick={onClick}
			>
				{icon && <Icon type={icon} size={'medium'} className={classes.icon} />}
				{children}
			</button>
		);
	}

	return (
		<NextLink
			{...rest}
			href={href}
			className={cx(classes.defaultLink, { [classes.buttonWithIcon]: !!icon }, className)}
			onClick={onClick}
		>
			<>
				{icon && <Icon type={icon} size={'medium'} className={classes.icon} />}
				{children}
			</>
		</NextLink>
	);
};

export { Link };
